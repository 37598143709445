<template>
  <div class="row mx-0 h-100">
    <div class="h-100 relative p-0" ref="theImage">
      <Image :imageName="newsItem.imageUrl" :width="theImageWidth" :height="theImageHeight" :alt="newsItem.name" theClass="d-block w-100 slider_img" />
      <!-- <img :src="url + '/static/uploads/images/' + newsItem.imageUrl" class="d-block w-100 slider_img" :alt="newsItem.name" /> -->
      <div class="overlay">
      </div>
      <div class="row mx-0 h-100 align-items-end abs">
        <div class="col-12 p-md-35">
          <div class="row">
            <div class="col-12 title mb-3">
              {{newsItem.name}}
            </div>
            <div class="col-9">
              <Button size="small" color="gold" :btnText="btn" icon="arrow" @buttonClicked="goToArticle">
                <IconArrowForward color="black" size="size16" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue')),
    Image: defineAsyncComponent(() => import('@/components/Image.vue'))
  },
  name: 'Article Search Card',
  props: ['newsItem', 'btn'],
  data () {
    return {
      url: process.env.VUE_APP_URL_BASE,
      theImageWidth: 0,
      theImageHeight: 0
    }
  },
  async mounted () {
    await this.getDimensions()
  },
  computed: {
    date () {
      const date = DateTime.fromMillis(this.newsItem.lastModifiedDate)
      return date.toLocaleString(DateTime.DATE_FULL)
    }
  },
  methods: {
    getDimensions () {
      this.theImageWidth = this.$refs.theImage.clientWidth
      this.theImageHeight = this.$refs.theImage.clientHeight
    },
    goToArticle () {
      window.scroll(0, 0)
      this.$router.push('/news/' + this.newsItem.id)
    }
  }
}
</script>

<style scoped>

.price {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: var(--green-color-dark);
  padding: 0.5rem 1rem;
  color: var(--gold-color);
  border-radius: 37px;
  width: auto;
  font-size: 0.8rem;
}
  .imageSlider-item {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
  .box_shadow {
    box-shadow: 0px 15px 24px 0px rgb(0 0 0 / 21%);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin: 15px 15px 15px 15px;
    padding: 45px 45px 45px 45px;
    border-radius: 15px;
  }
  .slider_img  {
    object-fit: cover;
    width: 100%;
    height: 279px;
    border-radius: 15px;
  }
  .p-md-35 {
    padding: 25px;
  }
  .overlay {
    background-color: transparent;
    background-image: linear-gradient(180deg, #00000000 17%, #000000 100%);
    opacity: 0.81;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 15px;
  }
  .abs {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .title {
    color: #FFFFFF;
    font-family: "quicksand_medium", Sans-serif;
    font-size: 1rem;
    font-weight: 600;
  }
@media (min-width: 992px) {
  .title {
    font-size: 1.62rem;
  }
  .price {
    position: absolute;
    top: 35px;
    left: 35px;
    background-color: var(--green-color-dark);
    padding: 7px 17px;
    color: var(--gold-color);
    border-radius: 37px;
    width: auto;
    font-size: 1rem;
  }
  .slider_img  {
    height: 285px;
  }
  .p-md-35 {
    padding: 35px;
  }
}

</style>
